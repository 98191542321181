$darkPink: #d73755;
$darkNavyBlue: #000016;
$white: #fff;
$whiteTwo: #fafafa;
$whiteThree: #f2f2f2;
$greyishBrow: #515151;
$veryLightPink: #c2c2c2;
$sicklyYellow: #afcb37;
$dullOrange: #f07e26;
$lighEggplant: #8c4091;
$sketchMesureSpacing: #50e3c2;
$waterBlue: #0d9fdb;
$black40: #660000;


