.app {
    &Loading {
        // container
        width: 100%;
        height: 100vh;

    }
}
html,body{
    height: 100%;
}
.link-none {
    text-decoration: none;
    color: #000000;
}
.link-none:hover{
    text-decoration: none;
    color: #000000;
}
.hasCursor {
    cursor: pointer;
}

.collectorEnd {
    position: inherit !important;
}