.diagnostics-card-carousel{
    overflow-x: scroll;
    overflow-y: hidden;
    padding-left: 17px;
    margin-top: 27px;
    margin-bottom: 47px;
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}

.diagnostics-card-carousel::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}

.vertical-card-container {
    width:200px;
    justify-content: space-between;
    padding-bottom: 10px;
    box-shadow:rgba(0, 0, 0, 0.2) 50px 0px 100px -25px, rgba(0, 0, 0, 0.2) -50px 0px 100px -25px;
}

.img-card-horizontal{
    object-fit: cover;
}

.card-text{
    text-align: left;
    font-size: 12px; 
    letter-spacing: 0px;
    color: #707070;
    opacity: 1; 
}

.card-title{
    text-align: left;
    font-weight: bold;
    font-size: 25px ;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;  
}
.card-faPen{
    text-align: left;
    font-size:  17px;
    letter-spacing: 0px;
    color: #140A02;
    opacity: 1;
    cursor: pointer;
}
.inverted-card-faPen{
    cursor: pointer;
    text-align: left;
    font-size:  17px;
    letter-spacing: 0px;
    background-color: #140A02;
    color: #fff;
    opacity: 1;
    padding: 10px;
    border-radius: 10px;
}
.inverted-card-faPen svg, .card-faPen svg {
    margin-right: 10px;
}

//************************************************
@media (max-width: 915px){ 
    .inverted-card-faPen,.card-faPen{
        font-size:  12px;
    }

    .card-title{
        font-size: 18px ;
    }
    .card-text{
        font-size: 13px; 
    }
}

.diag-tabs-mobile {
    display: none;
}
.diag-tabs-pc {
    display: block;
}
.card-horizotal-image {
    height:50px;
    width:50px;
    min-height:50px;
    min-width:200px;
}

@media (max-width: 870px){ 
    .diag-tabs-mobile {
        display: block;
    }
    .diag-tabs-pc {
        display: none;
    }
}