.itemCommunity, .isItemCommunityActive {
  cursor: pointer;
  font-family: Roboto;
  font-size: 14px ;
  letter-spacing: 0px;
  opacity: 1;
  max-width: calc( 100% - 80px);
  margin: 0;
}
.itemCommunity{
  text-align: left;
  color: #000000;
}
.isItemCommunityActive{
  font-weight: bold;
  color: #000000;
}
.logo_community{
  width: 38px;
  height: 38px;
  overflow: hidden;
  border: 2px solid white;
  border-radius: 5px;
  background-position-x: center; 
  object-fit: cover;
}

