
.skeleton-image{
    height: 100px;
    width: auto;
    border-radius: 6px;
    background: rgba(0, 0, 0, 0.11);;
    margin-top: 10px;
    animation: 1.5s ease-in-out 0.5s infinite normal none running skeleton-loading
   }

   @keyframes skeleton-loading {
    0% {
      opacity: 1;
    }

    50% {
        opacity: 0.4;
      }
  
    100% {
      opacity: 1;
    }
  }
  
   