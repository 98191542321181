.banner-logo-container {
   position: relative;
   background-color: white;
   float: right;
   height: 90%;
   border-radius: 100%;
   top: -95%;
   right: 15px;
}

.banner-container {
   position: relative;
   min-width: 100%;
   height: 100%;
}

.banner-container svg {
   height:100%;
   margin-left: 40px;
}

.banner-logo {
   height: 100%;
}

.ml10 { stroke-miterlimit:10; }
.ml50 { stroke-miterlimit:50; }
.sw3 { stroke-width:3; }
.sw5 { stroke-width:5; }
.sd7 { stroke-dasharray:7; }
.fillNoneStrokeBackground, .fillNoneStrokeColor { fill:none; }
.fillNoneStrokeColor { stroke:#FFFFFF; }
.fillColor { fill:#FFFFFF; }
.fillNoneStrokeBackground { stroke:#07AAD7; }
.fillBackground { fill:#07AAD7; }