.return-tab{
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;  
    font-family: Roboto;
    font-size: 18px;
}
.tabs-title{
    text-align: left;
    font-family: Roboto;
    font-size: 20px;
    letter-spacing: 0px;  
    opacity: 1; 
    cursor: pointer;
    .text-primary-wever.disabled{
        color: #C7C7C7!important;
    }
    
}
.border-primary-wever.border-disabled{
    border: none!important;  
}

.border-primary-wever.none{
    border: none!important;  
}

.border-tabs{
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 3px solid #C7C7C7;  

}
.tabs-title-color{
    color: #C7C7C7;
}
//************************************************
@media (max-width: 915px){ 
    .tabs-title{
        font-size: 20px;
    }
  }
  