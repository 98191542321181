// Small devices (landscape phones, 576px and up)
@media (max-width: 375px) {
  .sidebar {
    display: none;
  }
}
@media (max-width: 769px) {
  .sidebar {
    display: none;
  }
}
.widthOffcanvas{
 width: 90% !important; 
}
.pinkPeriod {
  color: #D73755;
}

.sidebarBackgroundExpanded, .sidebarBackground {
  position: absolute; 
  height: 100%;
  width: 100%;
  top:0;
  background-color: #F2F2F2;
  z-index: -1;
}

.sidebarBackgroundExpanded {
  transform: scale(1,1);
  left: 0px;
}

.sidebarBackground {
  transform: scale(0.23,1);
  left: -40%;
}

@media (min-width: 769px) {
  .sidebar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    height: 100%;
    position: absolute;
    z-index: 999;
  }
}
.sidebar {
  min-height: 100% !important;
  max-height: 100% !important;
  width: 270px;
  position: absolute;
  z-index: 999;
  background-color: none!important;
}
.poweredByWeverPart {
  width: 100%; 
  text-align: center;
}
.poweredByWeverPart img {
  height: 100px;
  object-fit: contain;
}
//**************active et custom link***************/
.sidebar-item {
  .icon {
    font-size: 20px;
    width: 20px;
    height: 20px;
    margin-left: 19px;
    margin-right: 17px;
    filter: brightness(0);
  }
  
  .active {
    color: #000000;
    font-weight: bold;
    font-family: Roboto;
    text-decoration: none;
    letter-spacing: 0px;
    display: flex;
    
    .pipe-left {
      border-left: 6px solid black;
      margin-left: 1px;
      margin-right: -7px;
    }
  }
}
.sidebar-link {
  color: #000000;
  filter: brightness(0%);
  font-weight: bold;
  font-family: Roboto;
  text-decoration: none;
  letter-spacing: 0px;
  display: flex;
}
.sidebar-link:hover{
  color: black;
}

/***************communities*****************/
.container-link-communities {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.communities {
  text-decoration: none;
  color: black;
  font-weight: 900;
  font-family: Roboto;
}
.container-icon-add-communities{
 
  width: 26px;
  height: 26px;
  max-height: 26px;
  max-width: 26px;
 
 .icon-add-communities {
  font-size: 10px;
  color: rgb(247, 241, 241);
  position: absolute;
  margin-left: -5px;
  margin-top: -5px;
} 
}
.container-communities-modal-sidebar{
  padding-bottom:22px ;
  margin-left: 5px;
}
.border-community, 
.border-community-disabled {
  width:35px;
  height:35px;
  min-width:35px;
  min-height:35px;
  border-color: transparent ;
  overflow: hidden;
  border-radius: 10px;
  margin-top: 4px;
  cursor: pointer;
}

.border-community{ border: 2px solid; }
.border-community-disabled{ border: none !important; }

.logoCommunitySidebar{
  width:100%;
  height:100%;
  object-fit: cover;
  background-color: white;
}

.expandButton {
  position: fixed;
  top: calc(100% - 100px);
  left: 250px;
  width: 40px;
  height: 40px;
  border-radius: 40px;
  z-index: 9999;
  border: none;
  color: white;
  transition: transform 0.5s
}

/*********** Modal *************/
.btn-modal-community{
  border: none !important;
  padding-left: 35px !important;
  padding-right: 35px !important;
}