/******************************************************new style*/
   .css-y1u3df-control ,  .css-tlfecz-indicatorContainer, .css-1okebmr-indicatorSeparator, .css-6j8wv5-Input , .css-1f43avz-a11yText-A11yText {
    background-color: transparent !important;
    border: none !important;
   
   }

.css-tlfecz-indicatorContainer{
 
    background-color: transparent !important;
}
.css-tj5bde-Svg{
    height:30px !important;
    width:30px !important;
    color: #000;
}

.css-qc6sy-singleValue{
    font-size: 20px;
    color: #000;
}