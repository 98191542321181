
//******************************************** MAGIC LOGIN *********************************************//
.magic-login {
  position: relative;
  .input-icon {
      margin-right: 2%;
      font-size: 1.8em;
      color: $waterBlue;   
  }

  input {
    height: 32px;
    letter-spacing: 2px;
    padding-bottom: 10px;
    text-align: start;
    font-size: 15px !important;

  }

  input:focus {
      background-color: inherit;
      border-color: inherit;
      box-shadow: none;
  }

  input::placeholder { 
    font-family: Roboto;
    font-size: 9px/12px ;
    letter-spacing: 0px;
    color: #707070;
  }

  button {
    background-color: #d73755;
    letter-spacing: 0px;
    color: #FFFFFF;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-family: Roboto;
    font-size: 16px !important;
    border-radius: 6px !important; 
    padding-left: 14%;
    padding-right: 14%;
    height: 32px !important;
    font-size: 16px;
    width: 150px !important;
  }
}
//******************* Login *****************//

.container-login{
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 100%;
  position: relative;
  background-color: #F4F4F4
}

//***************Header Login + Language*****************//
.header-connexion-page{   
  display: flex;
  justify-content: space-between;
}

.logo-container {
  width: 150px;
}

//**************container login form**************//
.login-form-container{
  z-index: 1;
  border: none;
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 99px #00000029;
  border-radius: 30px;
  opacity: 1;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 350px;
  height: 340px;
}

//******** Font size ***********//
.fs-connect{
  margin-top: 6px;
  font: normal normal normal 15px/15px Questrial;
}
.fs-text-login{
  text-align: center;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  font-size: 14px;
  line-height: normal;
  padding-bottom: 4%;
  padding-top: 4%;
}
.fs-text-magic-login{
  text-align: center;
  font: normal normal normal 18px Helvetica Neue;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.fs-title-login{
  text-align: center;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font: normal normal bold 27px/33px Helvetica Neue;
}
.fs-magic-link{
  text-align: center;
  text-decoration: underline;
  font: normal normal normal 11px/12px Helvetica Neue;
  font-family: roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  cursor: pointer;
}
//*********btn login***********//
.btn-form{
  background-color: #d73755;
  letter-spacing: 0px;
  color: #FFFFFF;
  border: none;
  text-transform: uppercase;
  font: normal normal bold 18px/23px Helvetica Neue;
  height: 31px;
}
.icon-login{
  margin-left: 76%;
  position: absolute;
  font-size: 10px;
  color: #000000;
}

.icon-login:hover{
  cursor:pointer
}

//*********************************************************** MOBILE ******************************************************//
//*************************************************************************************************************************//

//****************************** format mobile - portrait ******************************//
@media (max-width: 575.98px) { 
  .login-form-container {
    position: absolute;
    top: 50%!important;
  }

  .magic-login {
    position: relative;
    button{
      font: normal normal bold 12px Helvetica Neue !important;
      height: 31px;
    }
  }
  .fs-connect{
    margin-top: 9px;
    font: normal normal normal 12px/12px Questrial;
  }
  .fs-text-magic-login{
    font-size: 14.7px;
  }
  .contain-connect{
    padding-top: 3%;
  }
 }

//************************************************************************************//
//****************************** format mobile - paysage******************************//

@media (min-width: 576px) { 
  .magic-login {
    position: relative;
    button{
      font: normal normal bold 10px Helvetica Neue !important;
      height: 31px;
    }
  }
  .fs-connect{
    margin-top: 8px;
    font-size: 9px;
  }
  .fs-text-magic-login{
    font-size: 14px;
  }
    .contain-connect{
    padding-top: 3%;
  }
}
//************************************************************************************//
//****************************** format tablets *************************************//
@media (min-width: 769px) { 
  .magic-login {
    position: relative;
    button{
      font: normal normal bold 12px Helvetica Neue !important;
      height: 31px;
    }
  }
  .contain-connect{
    padding-top: 3%;
  }
  .fs-connect{
    margin-top: 8px;
    font-size: 10px;
  }
  .fs-text-magic-login{
    font-size: 14px;
  }
  
 }
//************************************************************************************//
//****************************** format desktops *************************************//
.contain-connect{
  padding-top: 1%;
}
.fs-connect{
  margin-top: 8px;
  font-size: 14px;
}
.fs-text-magic-login{
  font-size: 16px;
}
