@import './color.scss';
@import './utils.scss';
@import './components/common/navtabs.scss';
@import './components/common/skeleton.scss';
@import './components/common/diagnostics.cards';
@import './components/common/collector.scss';
@import './components/layout/sidebar';
@import './components/layout/navbar.scss';
@import './components/layout/languages.scss';
@import './components/layout/layout';
@import './views/login.page.scss';
@import './views/community.colors.scss';
@import './views/profile.scss';
@import './views/dashboard.page.scss';
@import "~bootstrap/scss/bootstrap";
@import "../../../node_modules/wever-core/src/assets/style.scss";

body {
  font-family: 'Roboto', sans-serif;
}

.graphapp-map {
    height: 600px !important;
    max-height: 600px !important;
  }
  
  .mapContainer {
    height: 600px !important;
  }




