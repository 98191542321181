/***************Barre de Navigation******************/
.logo-wever{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  padding-top: 10px;
}
.navbar-desktop {
  width: 100%;
  height: 55px;

  .nav-item {
    height: 55px;
  }

  .navbar-language {
    margin: 0!important;
    font-size: 1em;
    height: 30px;
    width: 80px;

    span[class*="-indicatorSeparator"] {
      display: none;
    }
    div[class*="-indicatorContainer"] {
      font-size: 0.8em;
      padding-left: 1px;
      padding-right: 15px;
    }
    
    div[class*="-ValueContainer"] {
      padding-right: 0px;
      padding-left: 8px;
    }

    div[class*="-listbox"] {
      width: 160px;

      div {
        width: 160px;
      }
    }
  }

  .icon-navbar {
    font-size: 25px;
    color: #183d4c;
    cursor: pointer;
    
  }
  .icon-navbar:focus{
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none !important;
    border: none !important;
  }
.nav-title{
  font-weight: bold;
  font-family: Roboto;
  font-size: 17px;
  color: #000000;
  width: calc(95% - 250px);
}
/***************** Dropdown react bootstrap ************************/
.dropdown-toggle::after {
  display: none !important; 
 
}

.dropdown {
  border: none !important;
button:focus{
  outline: none !important;
  outline-offset: none !important;
  box-shadow: none !important;
  border: none !important;
  
}
} 
.account-title {
  padding-top: 0 !important;

  .account-username-title {
    font-size: 0.65em;
    color: blue;
    margin-top: -3px;
  }
}

  /***************Badge rouge sur l'icon notification******************/
  .badge {
    font-size: 6px;
    margin-left: -10px;
    margin-top: 3px;
    position: absolute;
  }
  

}
/***********************#############################***************************************/
/***************Barre de Navigation Mobile ******************/

.short-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.navbar-mobile {
  .title {
    font-size: 0.85em;
  }

  .navbar-language {
    font-size: 0.8em;
    margin-top: 0!important;

    div[class*="-ValueContainer"] {
      padding-right: 3px;
    }


    div[class*="indicatorContainer"] {
      padding-left: 3px;
      padding-right: 0;

      svg {
        height: 15px;
        width: 15px;
      }
    }
  }


  .title-navbar-offcanvas {
    margin-top: 8%;
    font-size: 17px;
    font-weight: bold;
    font-family: Roboto;
  }
 

  /***************Badge rouge sur l'icon notification******************/
  .icon-navbar{
    margin-right: 20px;
  }
  .badge {
     font-size: 4px;
     margin-left: -27px;
    margin-top: 6px;
    position: absolute;
  }
 
  .icon-mobile {
    font-size: 40px;
    color: black;
    text-decoration: none;
  }
  .icon-mobile:focus-visible {
    outline: none;
  }
 
  .icon-mobile img {
    height: 40px;
    filter: brightness(0%);
  }

  /***************Barre de Navigation Mobile FIN******************/
}

/*************************Media query**********************/

@media (max-width: 576px) {
  .navbar-desktop {
    display: none;
  }
  .short-text-container {
    width: 220px;
  
  }
  .account-title {
    display: none;
  }
}
@media (min-width: 576px) {
  .navbar-desktop {
    display: none;
  }
}

@media (max-width: 360px) {

  .short-text-container {
    width: 210px;
  
  }
 
}
@media (max-width: 280px) {

  .short-text-container {
    width: 133px;
  
  }
 
}