.btn-close{
    margin: 0 !important;
}

.btn-wever{
     width: 20% !important; 
}
@media only screen and (max-width: 769px){
  .btn-wever {
    width: 50% !important; 
}  
}
.rectangle-blue:focus{
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border-radius: 0 !important;
}